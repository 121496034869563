import React from 'react';
import { MenuItemLink } from 'react-admin';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BiotechIcon from '@mui/icons-material/Biotech';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MoneyIcon from '@mui/icons-material/Money';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import NoteAddSharpIcon from '@mui/icons-material/NoteAddSharp';
import PetsIcon from '@mui/icons-material/Pets';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SendIcon from '@mui/icons-material/Send';
import StoreIcon from '@mui/icons-material/Store';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import TokenIcon from '@mui/icons-material/Token';
import TollIcon from '@mui/icons-material/Toll';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import WcIcon from '@mui/icons-material/Wc';

import { shouldRender } from '../../Components/lib/helpers';

export const renderMenuItem = ({ item, permissions, dense, open, ...rest }) => {
    if (item.needAccess) {
        if (shouldRender(permissions, item.role)) {
            return (
                <MenuItemLink
                    key={item.name}
                    leftIcon={item.icon || null}
                    to={`/${item.name}`}
                    primaryText={item.primaryText}
                    sidebarIsOpen={open}
                    dense={dense}
                    {...rest}
                />
            );
        }
        return null;
    }

    return (
        <MenuItemLink
            key={item.name}
            to={`/${item.name}`}
            leftIcon={item.icon || null}
            primaryText={item.primaryText}
            sidebarIsOpen={open}
            dense={dense}
            {...rest}
        />
    );
};

export const menuItems = {
    operations: [
        {
            name: 'specimens',
            role: 'specimens',
            primaryText: 'Specimens',
            icon: <PetsIcon />,
            needAccess: true,
        },
        {
            name: 'specimen-schedules',
            // role: 'specimen-schedules',
            role: 'specimens',
            primaryText: 'Specimen Schedules',
            icon: <CalendarMonthIcon />,
            needAccess: true,
        },
        {
            name: 'medication-records',
            role: 'medication-records',
            primaryText: 'Medication Records',
            icon: <FolderSharedIcon />,
            needAccess: true,
        },
        {
            name: 'breeding-records',
            role: 'breeding-records',
            primaryText: 'Breeding Records',
            icon: <WcIcon />,
            needAccess: true,
        },
        {
            name: 'specimen-transfers',
            role: 'specimen-transfers',
            primaryText: 'Transfers',
            icon: <SyncOutlinedIcon />,
            needAccess: true,
        },
    ],
    supplyChain: [
        { name: 'accounts', role: 'accounts', primaryText: 'Accounts', icon: <AccountBalanceIcon />, needAccess: true },
        { name: 'bills', role: 'bills', primaryText: 'Bills', icon: <MoneyIcon />, needAccess: true },
        {
            name: 'bill-payments',
            role: 'bill-payments',
            primaryText: 'Payments',
            icon: <AttachMoneyIcon />,
            needAccess: true,
        },
        {
            name: 'payment-terms',
            role: 'payment-terms',
            primaryText: 'Payment Terms',
            icon: <AccountBalanceWalletIcon />,
            needAccess: true,
        },
        {
            name: 'purchase-orders',
            role: 'purchase-orders',
            primaryText: 'Purchase Orders',
            icon: <AddShoppingCartIcon />,
            needAccess: true,
        },
        {
            name: 'purchase-requisitions',
            role: 'purchase-requisitions',
            primaryText: 'Requisitions',
            icon: <SendIcon />,
            needAccess: true,
        },
        {
            name: 'item-receipts',
            role: 'item-receipts',
            primaryText: 'Item Receipts',
            icon: <ReceiptIcon />,
            needAccess: true,
        },
        {
            name: 'item-locations',
            role: 'item-locations',
            primaryText: 'Item Locations',
            icon: <MyLocationIcon />,
            needAccess: true,
        },
        {
            name: 'inventory-by-location',
            role: 'item-item-location',
            primaryText: 'Inventory',
            icon: <StoreIcon />,
            needAccess: true,
        },
        {
            name: 'inventory-adjustments',
            role: 'inventory-adjustments',
            primaryText: 'Inv. Adjustments',
            icon: <SyncAltIcon />,
            needAccess: true,
        },
        {
            name: 'inventory-reports',
            role: 'inventory-reports',
            primaryText: 'Inv. Reports',
            icon: <NoteAddSharpIcon />,
            needAccess: true,
        },
        {
            name: 'customers',
            role: 'customers',
            primaryText: 'Customers',
            icon: <BusinessCenterIcon />,
            needAccess: true,
        },
        {
            name: 'vendors',
            role: 'vendors',
            primaryText: 'Vendors',
            icon: <EmojiTransportationIcon />,
            needAccess: true,
        },
    ],
    settings: [
        {
            name: 'codons',
            role: 'codons',
            primaryText: 'Codons',
            icon: <BiotechIcon />,
            needAccess: true,
        },
        {
            name: 'contacts',
            role: 'contacts',
            primaryText: 'Contacts',
            icon: <ContactMailIcon />,
            needAccess: true,
        },
        {
            name: 'items',
            role: 'items',
            primaryText: 'Items',
            icon: <LocalOfferIcon />,
            needAccess: true,
        },
        {
            name: 'item-types',
            role: 'item-types',
            primaryText: 'Item Types',
            icon: <TokenIcon />,
            needAccess: true,
        },
        {
            name: 'item-subtypes',
            role: 'item-subtypes',
            primaryText: 'Item Subtypes',
            icon: <TollIcon />,
            needAccess: true,
        },
        {
            name: 'medications',
            role: 'items',
            primaryText: 'Medications',
            icon: <LocalHospitalIcon />,
            needAccess: true,
        },
        {
            name: 'medication-routes',
            role: 'medication-routes',
            primaryText: 'Med. Routes',
            icon: <VaccinesIcon />,
            needAccess: true,
        },
        {
            name: 'organizations',
            role: 'organizations',
            primaryText: 'Organizations',
            icon: <GroupIcon />,
            needAccess: true,
        },
        {
            name: 'pens',
            role: 'pens',
            primaryText: 'Pens',
            icon: <HomeIcon />,
            needAccess: true,
        },
        {
            name: 'facilities',
            role: 'facilities',
            primaryText: 'Facilities',
            icon: <LocationCityIcon />,
            needAccess: true,
        },
        {
            name: 'units-of-measure',
            role: 'units-of-measure',
            primaryText: 'Units of Measure',
            icon: <AssessmentIcon />,
            needAccess: true,
        },
    ],
    admin: [
        { name: 'users', role: 'users', primaryText: 'Users', icon: <AccessibilityIcon />, needAccess: true },
        { name: 'roles', role: 'roles', primaryText: 'Roles', icon: <VerifiedUserIcon />, needAccess: true },
        {
            name: 'review-rules',
            role: 'review-rules',
            primaryText: 'Review Rules',
            icon: <AnnouncementIcon />,
            needAccess: true,
        },
    ],
};

export const menuRoles = {
    operationsMenu: menuItems.operations.map(item => item.role),
    supplyChainMenu: menuItems.supplyChain.map(item => item.role),
    settingsMenu: menuItems.settings.map(item => item.role),
    adminMenu: menuItems.admin.map(item => item.role),
};

export const menuPaths = {
    specimens: { name: 'operationsMenu', subName: null },
    'specimen-schedules': { name: 'operationsMenu', subName: null },
    'medication-records': { name: 'operationsMenu', subName: null },
    'breeding-records': { name: 'operationsMenu', subName: null },
    'specimen-transfers': { name: 'operationsMenu', subName: null },
    accounts: { name: 'supplyChainMenu', subName: null },
    bills: { name: 'supplyChainMenu', subName: null },
    'payment-terms': { name: 'supplyChainMenu', subName: null },
    'bill-payments': { name: 'supplyChainMenu', subName: null },
    'purchase-orders': { name: 'supplyChainMenu', subName: null },
    'purchase-requisitions': { name: 'supplyChainMenu', subName: null },
    'item-receipts': { name: 'supplyChainMenu', subName: null },
    'item-locations': { name: 'supplyChainMenu', subName: null },
    'inventory-by-location': { name: 'supplyChainMenu', subName: null },
    'inventory-adjustments': { name: 'supplyChainMenu', subName: null },
    'inventory-reports': { name: 'supplyChainMenu', subName: null },
    customers: { name: 'supplyChainMenu', subName: null },
    vendors: { name: 'supplyChainMenu', subName: null },
    codons: { name: 'settingsMenu', subName: null },
    contacts: { name: 'settingsMenu', subName: null },
    items: { name: 'settingsMenu', subName: null },
    // 'item-types': { name: 'settingsMenu', subName: null },
    'item-subtypes': { name: 'settingsMenu', subName: null },
    medications: { name: 'settingsMenu', subName: null },
    'medication-routes': { name: 'settingsMenu', subName: null },
    organizations: { name: 'settingsMenu', subName: null },
    pens: { name: 'settingsMenu', subName: null },
    facilities: { name: 'settingsMenu', subName: null },
    'units-of-measure': { name: 'settingsMenu', subName: null },
    users: { name: 'adminMenu', subName: null },
    roles: { name: 'adminMenu', subName: null },
    'review-rules': { name: 'adminMenu', subName: null },
};
