import React, { useCallback, useMemo } from 'react';
import { Create, SimpleForm, useRecordContext, usePermissions } from 'react-admin';
import WcIcon from '@mui/icons-material/Wc';
import { DialogForm, CustomToolbar } from '../formComponents';
import { shouldRender } from '../../helpers';
import OverviewInputs from '../../../Operations/BreedingRecords/BreedingRecordsForms/BreedingRecordsFormHelpers';
import BreedingRecordsLinesForm from '../../../Operations/BreedingRecords/BreedingRecordsForms/BreedingRecordsLinesForm';
import { transformBreedingRecords } from '../../../Operations/BreedingRecords/BreedingRecordsForms';

const sanitizeProps = ({ record, hasShow, hasCreate, hasEdit, hasList, ...rest }: any) => rest;

const BreedButton = props => {
    const record = useRecordContext(props);
    const { permissions } = usePermissions();

    const canCreateBreedingRecords = useMemo(
        () => shouldRender(permissions, 'breeding-records', 'can_create'),
        [permissions]
    );

    const defaultValues: any = useMemo(
        () =>
            // eslint-disable-next-line no-nested-ternary
            record
                ? 'Male' === record.gender
                    ? { male_specimen_id: record.id }
                    : { breeding_record_lines: [{ specimen_id: record.id }] }
                : {},
        [record]
    );

    const onSuccess = useCallback(({ data, closeDialog }) => {
        closeDialog();
    }, []);

    if (!canCreateBreedingRecords) {
        return null;
    }

    return (
        <DialogForm
            title="Create Breeding Record"
            label="Breed"
            maxWidth="md"
            icon={<WcIcon />}
            passCloseDialogToForm={false}
            buttonMarginLeft={1}
        >
            <Create component="div" resource="breeding-records" {...sanitizeProps(props)}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            resourceOverride="breeding-records"
                            transform={transformBreedingRecords}
                            onSuccess={onSuccess}
                            noInitialRequirement
                            {...sanitizeProps(props)}
                        />
                    }
                    defaultValues={defaultValues}
                    redirect={false}
                >
                    <OverviewInputs />
                    <BreedingRecordsLinesForm />
                </SimpleForm>
            </Create>
        </DialogForm>
    );
};

export default BreedButton;
