import { createTheme } from '@mui/material';
import { defaultTheme } from 'react-admin';

const overrides: any = {
    typography: {
        title: {
            fontWeight: 400,
        },
    },
    components: {
        ...defaultTheme.components,
        RaListToolbar: {
            styleOverrides: {
                root: {
                    justifyContent: 'flex-start',
                    flexDirection: 'column-reverse',
                    alignItems: 'flex-start',
                    flex: 'auto',
                },
            },
        },
    },
};

export const darkTheme = createTheme({
    ...overrides,
    palette: {
        mode: 'dark',
    },
});

export const lightTheme = createTheme({
    ...overrides,
    palette: {
        secondary: {
            light: '#6ec6ff',
            main: '#2196f3',
            dark: '#0069c0',
            contrastText: '#fff',
        },
    },
});
