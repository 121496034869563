import React, { useCallback, useMemo } from 'react';
import { required, SelectInput, TextInput } from 'react-admin';
import { useWatch, useFormContext } from 'react-hook-form';
import { FacilityInput, PenInput } from '../../../../lib/customComponents/formComponents/CommonFormInputs';
import { FullScreen } from '../../../../lib/styles/StyledComponents';

const locationTypeChoices = [
    { id: 1, name: 'Pen', value: 'Pen' },
    { id: 2, name: 'Facility', value: 'Facility' },
];

const FemaleFilterInputs = ({ femaleSpecimenFilters }) => {
    const locationType = useWatch({ name: 'location_type', defaultValue: femaleSpecimenFilters?.location_type });

    const { setValue } = useFormContext();

    const handleLocationTypeChange = useCallback(
        ev => {
            const { value } = ev.target;

            if (!value) {
                setValue('modal_facility_id', null);
                setValue('modal_pen_id', null);
                return;
            }

            if ('Pen' === value) {
                setValue('modal_facility_id', null);
            } else if ('Facility' === value) {
                setValue('modal_pen_id', null);
            }
        },
        [setValue]
    );

    return useMemo(
        () => (
            <>
                <FullScreen>
                    <TextInput
                        source="modal_gender"
                        label=" Gender"
                        defaultValue={femaleSpecimenFilters?.gender}
                        disabled
                    />
                </FullScreen>
                <FullScreen>
                    <SelectInput
                        source="location_type"
                        label=" Location Type"
                        choices={locationTypeChoices}
                        optionValue="value"
                        emptyText="Clear Selection"
                        onChange={handleLocationTypeChange}
                        validate={required()}
                        defaultValue={femaleSpecimenFilters?.location_type}
                    />
                </FullScreen>
                {locationType && (
                    <FullScreen>
                        {'Pen' === locationType ? (
                            <PenInput
                                source="modal_pen_id"
                                defaultValue={femaleSpecimenFilters?.pen_id}
                                validate={required()}
                            />
                        ) : (
                            <FacilityInput
                                source="modal_facility_id"
                                defaultValue={femaleSpecimenFilters?.facility_id}
                                validate={required()}
                            />
                        )}
                    </FullScreen>
                )}
            </>
        ),
        [femaleSpecimenFilters, locationType, handleLocationTypeChange]
    );
};

export default FemaleFilterInputs;
