import React from 'react';
import { BooleanField, DateField, TextField, Show, Tab, TabbedShowLayout, NumberField } from 'react-admin';
import {
    AttachmentPreviewField,
    BreedButton,
    FilteredFieldsRenderer,
    LocalReferenceField,
    MedicateButton,
    TransferButton,
} from '../../lib/customComponents/showComponents';
import { getUserSettings, sanitizeTabbedShowProps } from '../../lib/helpers';
import { CustomActionsShowView } from '../../lib/helpers/customActions';
import { SpecimenMedicationRecords, SpecimenBreedingRecordLines } from './index';

const getSpecimensFields = () => [
    <TextField source="id" label="ID" />,
    <TextField source="name" label="Name" />,
    <TextField source="gender" label="Gender" />,
    <LocalReferenceField source="father_name" label="Father" reference="specimens" passProps />,
    <LocalReferenceField source="mother_name" label="Mother" reference="specimens" passProps />,
    <TextField source="dna_number" label="DNA No." />,
    <TextField source="tag_number" label="Tag No." />,
    <TextField source="state_number" label="State No." />,
    <TextField source="electronic_number" label="Electronic No." />,
    <LocalReferenceField source="codon_37_id" label="Codon 37" reference="codons" passProps />,
    <LocalReferenceField source="codon_95_id" label="Codon 95" reference="codons" passProps />,
    <LocalReferenceField source="codon_96_id" label="Codon 96" reference="codons" passProps />,
    <LocalReferenceField source="codon_116_id" label="Codon 116" reference="codons" passProps />,
    <LocalReferenceField source="codon_226_id" label="Codon 226" reference="codons" passProps />,
    <NumberField source="50k_gebv" options={{ minimumFractionDigits: 2, maximumFractionDigits: 6 }} />,
    <LocalReferenceField
        source="bred_at"
        idSource="breeding_record_id"
        reference="breeding-records"
        label="Bred At"
        passProps
        isDateField
    />,
    <DateField source="born_at" label="Born At" showTime />,
    <DateField source="died_at" label="Died At" showTime />,
    <DateField source="transferred_at" label="Died At" showTime />,
    <TextField source="transferred_to_entity" label="Transferred To Entity" />,
    <TextField source="transferred_to_name" label="Transferred To" />,
    <BooleanField label="Active" source="is_active" />,
    <DateField source="created_at" label="Created At" showTime />,
    <DateField source="updated_at" label="Updated At" showTime />,
];

export const SpecimensShowFields = props => (
    <FilteredFieldsRenderer getFieldsFunction={getSpecimensFields} {...props}>
        <SpecimenMedicationRecords {...props} />
        <SpecimenBreedingRecordLines {...props} />
        <AttachmentPreviewField {...props} />
    </FilteredFieldsRenderer>
);

const SpecimenActions = props => (
    <CustomActionsShowView {...props}>
        <BreedButton {...props} />
        <MedicateButton {...props} />
        <TransferButton {...props} />
    </CustomActionsShowView>
);

const SpecimensTabbedShow = props => (
    <Show actions={<SpecimenActions {...props} />} {...props}>
        <TabbedShowLayout {...sanitizeTabbedShowProps(props)}>
            <FilteredFieldsRenderer getFieldsFunction={getSpecimensFields} isTabbedShow {...props} />
            <Tab label="Medication Records" path="medication-records">
                <SpecimenMedicationRecords {...props} />
            </Tab>
            <Tab label="Breeding Records" path="breeding-records">
                <SpecimenBreedingRecordLines {...props} />
            </Tab>
            <Tab label="Attachments" path="attachments">
                <AttachmentPreviewField {...props} />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const SpecimensSimpleShow = props => (
    <Show actions={<SpecimenActions {...props} />} {...props}>
        <SpecimensShowFields {...props} />
    </Show>
);

export const SpecimensShow = props =>
    'simple' === getUserSettings('show_view_format', 'tabbed') ? (
        <SpecimensSimpleShow {...props} />
    ) : (
        <SpecimensTabbedShow {...props} />
    );
