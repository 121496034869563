import React, { useCallback } from 'react';
import { Button, Toolbar } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import FilterIcon from '@mui/icons-material/FilterList';

const FemaleFilterToolbar = ({ femaleSpecimenFilters, setFemaleFilters, onSuccess, ...props }) => {
    const { getValues, formState } = useFormContext();

    const handleConfirm = useCallback(
        (isSetAll = false) => {
            const values = getValues();
            onSuccess({ ...values, isSetAll });
        },
        [onSuccess, getValues]
    );

    return (
        <Toolbar>
            <Button
                sx={{ ml: 'auto', mr: '1em' }}
                label="Select All"
                variant="contained"
                size="medium"
                disabled={!formState.isValid}
                onClick={ev => handleConfirm(true)}
            >
                <DoneAllIcon />
            </Button>
            <Button
                sx={{ mr: '1em' }}
                label="Set Filters"
                variant="contained"
                size="medium"
                disabled={!formState.isValid}
                onClick={ev => handleConfirm()}
            >
                <FilterIcon />
            </Button>
        </Toolbar>
    );
};

export default FemaleFilterToolbar;
