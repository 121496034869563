import React from 'react';
import { Create, Edit, FormTab, SimpleForm, TabbedForm } from 'react-admin';
import { ResponsiveForm, CustomToolbar, formMode, QueryWrapper } from '../../../lib/customComponents/formComponents';
import { CustomActionsEditForm } from '../../../lib/helpers/customActions';
import OverviewInputs from './BreedingRecordsFormHelpers';
import BreedingRecordsLinesForm from './BreedingRecordsLinesForm';

const getZeroOrNullValues = val => {
    if (0 === val) {
        return 0;
    }
    if (val) {
        return val;
    }
    return null;
};

const sanitizedLines = lines =>
    lines.map(item => ({
        id: item.id,
        specimen_id: item.specimen_id,
        specimen_weight_pounds: getZeroOrNullValues(item.specimen_weight_pounds),
        specimen_score: getZeroOrNullValues(item.specimen_score),
        notes: item.notes,
    }));

export const transformBreedingRecords = rec => {
    const newRecord: any = {
        administered_by_id: rec.administered_by_id,
        male_specimen_id: rec.male_specimen_id,
        type: rec.type,
        bred_at: rec.bred_at,
        male_specimen_weight_pounds: getZeroOrNullValues(rec.male_specimen_weight_pounds),
        male_specimen_score: getZeroOrNullValues(rec.male_specimen_score),
        notes: rec.notes,
        is_active: rec.is_active,
        breeding_record_lines: sanitizedLines(rec.breeding_record_lines),
    };
    if (rec.id) {
        newRecord.id = rec.id;
    }
    return newRecord;
};

export const SimpleCreate = ({ defaultValues = {}, onSuccess = null, ...rest }) => (
    <Create title="Create Breeding Record">
        <SimpleForm
            defaultValues={defaultValues}
            toolbar={<CustomToolbar transform={transformBreedingRecords} onSuccess={onSuccess} />}
            mode={formMode}
        >
            <OverviewInputs />
            <BreedingRecordsLinesForm />
        </SimpleForm>
    </Create>
);

const TabbedCreate = () => (
    <Create title="Create Breeding Record">
        <TabbedForm toolbar={<CustomToolbar transform={transformBreedingRecords} />} mode={formMode}>
            <FormTab label="Overview">
                <OverviewInputs />
            </FormTab>
            <FormTab label="Items">
                <BreedingRecordsLinesForm />
            </FormTab>
        </TabbedForm>
    </Create>
);

const SimpleEdit = () => (
    <Edit title="Edit Breeding Record" actions={<CustomActionsEditForm />}>
        <QueryWrapper relations={['breeding_record_lines']}>
            <SimpleForm toolbar={<CustomToolbar transform={transformBreedingRecords} />} mode={formMode}>
                <OverviewInputs edit />
                <BreedingRecordsLinesForm edit />
            </SimpleForm>
        </QueryWrapper>
    </Edit>
);

const TabbedEdit = () => (
    <Edit title="Edit Breeding Record" actions={<CustomActionsEditForm />}>
        <QueryWrapper relations={['breeding_record_lines']}>
            <TabbedForm toolbar={<CustomToolbar transform={transformBreedingRecords} />} mode={formMode}>
                <FormTab label="Overview">
                    <OverviewInputs edit />
                </FormTab>
                <FormTab label="Items">
                    <BreedingRecordsLinesForm edit />
                </FormTab>
            </TabbedForm>
        </QueryWrapper>
    </Edit>
);

export const BreedingRecordsEditForm = () => <ResponsiveForm simpleForm={<SimpleEdit />} tabbedForm={<TabbedEdit />} />;

export const BreedingRecordsCreateForm = () => (
    <ResponsiveForm simpleForm={<SimpleCreate />} tabbedForm={<TabbedCreate />} />
);
