import React, { useState, useCallback, useEffect, useRef, cloneElement } from 'react';
import type { FC, ReactNode } from 'react';
import { Button, useRefresh } from 'react-admin';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormWithProps from './FormWithProps';

interface IDialogForm {
    [x: string]: any;
    buttonProps?: { [x: string]: any };
    children: ReactNode;
    title?: string;
    label?: string;
    dialogContent?: any;
    dialogContentTitle?: string;
    icon?: JSX.Element;
    button?: any;
    disabled?: boolean;
    onBeforeOpen?: () => Promise<boolean | void>;
    onClose?: () => void;
    content?: any;
    buttonMarginLeft?: string | number;
    maxWidth?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl';
    passCloseDialogToForm?: boolean;
}

const DialogForm: FC<IDialogForm> = ({
    buttonProps = {},
    children,
    title,
    label,
    dialogContent,
    dialogContentTitle,
    icon,
    button: buttonOverride,
    disabled,
    onBeforeOpen,
    onClose,
    content,
    maxWidth = 'sm',
    buttonMarginLeft = 'auto',
    passCloseDialogToForm = true,
    ...props
}) => {
    const [showDialog, setShowDialog] = useState<boolean>(false);

    const isMountedRef = useRef(false);

    const refresh = useRefresh();

    const handleClick = useCallback(
        async ev => {
            ev.stopPropagation();
            if (onBeforeOpen && 'function' === typeof onBeforeOpen) {
                await onBeforeOpen()
                    .then(() => setShowDialog(true))
                    .catch(err => {
                        throw new Error(err);
                    });
            } else {
                setShowDialog(true);
            }
        },
        [onBeforeOpen]
    );

    const handleCloseClick = useCallback(
        (refreshView = false) => {
            if (refreshView && isMountedRef.current) {
                refresh();
            }
            if ('function' === typeof onClose && isMountedRef.current) {
                onClose();
            }
            if (isMountedRef.current) {
                setShowDialog(false);
            }
        },
        [onClose, refresh]
    );

    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    return (
        <>
            {buttonOverride ? (
                cloneElement(buttonOverride, {
                    onClick: handleClick,
                    label: label || title,
                })
            ) : (
                <Button
                    onClick={handleClick}
                    label={label || title}
                    sx={{ ml: buttonMarginLeft }}
                    disabled={disabled}
                    {...buttonProps}
                >
                    {icon}
                </Button>
            )}
            <Dialog
                fullWidth
                maxWidth={maxWidth}
                open={showDialog}
                onClose={(event, reason) => {
                    if ('backdropClick' !== reason) {
                        handleCloseClick();
                    }
                }}
                onClick={ev => ev.stopPropagation()}
                disableEscapeKeyDown
                {...props}
            >
                <DialogTitle>
                    {title}
                    <Tooltip title="Close">
                        <IconButton
                            onClick={() => handleCloseClick()}
                            sx={{ color: 'darkgray', right: 8, top: 8, position: 'absolute' }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </DialogTitle>
                <DialogContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {'string' === typeof content ? <Typography>{content}</Typography> : content}
                    <FormWithProps
                        form={children}
                        closeDialog={handleCloseClick}
                        passCloseDialogToForm={passCloseDialogToForm}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DialogForm;
