import React from 'react';
import { AutocompleteInput, SelectInput, TextInput, NumberInput, ReferenceInput } from 'react-admin';
import CreateSuggestionFormWrapper from './CreateSuggestionFormWrapper';
import { formatDate, phoneNumberFormat } from '../../helpers';

type CommonFormInputProps = {
    [x: string]: any;
    label?: string;
    source?: string;
    filter?: any;
    fieldSelectOnFilterLine?: string[];
    sort?: {
        field: string;
        order: string;
    };
    perPage?: number;
    inputComponent?: JSX.Element;
};

const AccountInput: React.FC<CommonFormInputProps> = ({
    label = 'Account',
    source = 'account_id',
    defaultCreateValues = {},
    filter = {},
    fieldSelectOnFilterLine = ['id', 'name'],
    inputComponent = (
        <AutocompleteInput
            label={label}
            shouldRenderSuggestions={val => 'undefined' !== typeof val && 2 < val.trim().length}
            filterToQuery={searchText => ({ name: `%${searchText}%` })}
            optionText="name"
            emptyText="No accounts found"
            create={<CreateSuggestionFormWrapper defaultCreateValues={defaultCreateValues} />}
        />
    ),
    perPage = 100,
    ...rest
}) => (
    <ReferenceInput
        source={source}
        reference="accounts"
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ ...filter, fieldSelectOnFilterLine }}
        enableGetChoices={({ name }) => 'undefined' !== typeof name && 2 < name.trim().length}
        perPage={perPage}
    >
        {React.cloneElement(inputComponent, { ...rest })}
    </ReferenceInput>
);

const breedingRecordOptionText = rec =>
    // eslint-disable-next-line no-nested-ternary
    rec
        ? rec.name && '@@ra-create' === rec.id
            ? rec.name
            : `${rec.specimen_name} & ${rec.male_specimen_name} at ${formatDate(rec.bred_at, 'short-date-time')} via ${
                  rec.type
              }`
        : null;

const BreedingRecordInput: React.FC<CommonFormInputProps> = ({
    label = 'Breeding Record',
    source = 'breeding_record_id',
    defaultCreateValues = {},
    enableGetChoices = () => true,
    filter = {},
    fieldSelectOnFilterLine = [
        'id',
        'male_specimen_id',
        'male_specimen_name',
        'specimen_id',
        'specimen_name',
        'bred_at',
        'type',
    ],
    sort = { field: 'bred_at', order: 'DESC' },
    inputComponent = (
        <SelectInput
            label={label}
            createLabel="Create Breeding Record"
            emptyText="Clear Selection"
            optionText={breedingRecordOptionText}
            create={<CreateSuggestionFormWrapper defaultCreateValues={defaultCreateValues} />}
        />
    ),
    ...rest
}) => (
    <ReferenceInput
        source={source}
        reference="breeding-records"
        filter={{ ...filter, fieldSelectOnFilterLine }}
        enableGetChoices={enableGetChoices}
        sort={sort}
    >
        {React.cloneElement(inputComponent, { ...rest })}
    </ReferenceInput>
);

const CodonInput: React.FC<CommonFormInputProps> = ({
    label = 'Codon',
    source = 'codon_id',
    defaultCreateValues = {},
    filter = {},
    fieldSelectOnFilterLine = ['id', 'name'],
    inputComponent = (
        <AutocompleteInput
            label={label}
            create={<CreateSuggestionFormWrapper defaultCreateValues={defaultCreateValues} />}
            shouldRenderSuggestions={val => 'undefined' !== typeof val && 2 < val.trim().length}
            filterToQuery={searchText => ({ name: `%${searchText}%` })}
            optionText="name"
            emptyText="Clear Selection"
        />
    ),
    perPage = 100,
    ...rest
}) => (
    <ReferenceInput
        source={source}
        reference="codons"
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ ...filter, fieldSelectOnFilterLine }}
        enableGetChoices={({ name }) => 'undefined' !== typeof name && 2 < name.trim().length}
        perPage={perPage}
    >
        {React.cloneElement(inputComponent, { ...rest })}
    </ReferenceInput>
);

const ContactInput: React.FC<CommonFormInputProps> = ({
    label = 'Contact',
    source = 'contact_id',
    defaultCreateValues = {},
    filter = {},
    fieldSelectOnFilterLine = ['id', 'name'],
    inputComponent = (
        <AutocompleteInput
            label={label}
            create={<CreateSuggestionFormWrapper defaultCreateValues={defaultCreateValues} />}
            shouldRenderSuggestions={val => 'undefined' !== typeof val && 2 < val.trim().length}
            noOptionsText="No contacts found"
            filterToQuery={searchText => ({ name: `%${searchText}%` })}
            optionText="name"
            emptyText="Clear Selection"
        />
    ),
    perPage = 100,
    ...rest
}) => (
    <ReferenceInput
        source={source}
        reference="contacts"
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ ...filter, fieldSelectOnFilterLine }}
        enableGetChoices={({ name }) => 'undefined' !== typeof name && 2 < name.trim().length}
        perPage={perPage}
    >
        {React.cloneElement(inputComponent, { ...rest })}
    </ReferenceInput>
);

const CustomerInput: React.FC<CommonFormInputProps> = ({
    label = 'Customer',
    source = 'customer_id',
    defaultCreateValues = {},
    filter = {},
    fieldSelectOnFilterLine = ['id', 'name'],
    inputComponent = (
        <AutocompleteInput
            label={label}
            create={<CreateSuggestionFormWrapper defaultCreateValues={defaultCreateValues} />}
            shouldRenderSuggestions={val => 'undefined' !== typeof val && 2 < val.trim().length}
            filterToQuery={searchText => ({ name: `%${searchText}%` })}
            optionText="name"
            noOptionsText="No customers found"
        />
    ),
    perPage = 100,
    ...rest
}) => (
    <ReferenceInput
        source={source}
        reference="customers"
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ ...filter, fieldSelectOnFilterLine }}
        enableGetChoices={({ name }) => 'undefined' !== typeof name && 2 < name.trim().length}
        perPage={perPage}
    >
        {React.cloneElement(inputComponent, { ...rest })}
    </ReferenceInput>
);

const FacilityInput: React.FC<CommonFormInputProps> = ({
    label = 'Facility',
    source = 'facility_id',
    defaultCreateValues = {},
    filter = {},
    fieldSelectOnFilterLine = ['id', 'name'],
    inputComponent = (
        <AutocompleteInput
            label={label}
            create={<CreateSuggestionFormWrapper label="Facility" defaultCreateValues={defaultCreateValues} />}
            shouldRenderSuggestions={val => 'undefined' !== typeof val && 2 < val.trim().length}
            filterToQuery={searchText => ({ name: `%${searchText}%` })}
            optionText="name"
            noOptionsText="No facilities found"
        />
    ),
    perPage = 100,
    ...rest
}) => (
    <ReferenceInput
        source={source}
        reference="facilities"
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ ...filter, fieldSelectOnFilterLine }}
        enableGetChoices={({ name }) => 'undefined' !== typeof name && 2 < name.trim().length}
        perPage={perPage}
    >
        {React.cloneElement(inputComponent, { ...rest })}
    </ReferenceInput>
);

const HourInput = props => (
    <NumberInput
        format={val => {
            if (!val) {
                return 0;
            }
            return 0 !== val ? Math.round(val * 60) : val;
        }}
        parse={val => val / 60}
        {...props}
    />
);

const ItemsInput: React.FC<CommonFormInputProps> = ({
    label = 'Item',
    source = 'item_id',
    defaultCreateValues = {},
    filter = {
        is_active: true,
    },
    fieldSelectOnFilterLine = ['id', 'name', 'is_active', 'type', 'unit_of_measure_name'],
    sort = { field: 'name', order: 'ASC' },
    inputComponent = (
        <AutocompleteInput
            label={label}
            create={<CreateSuggestionFormWrapper defaultCreateValues={defaultCreateValues} />}
            shouldRenderSuggestions={val => 'undefined' !== typeof val && 2 < val.trim().length}
            filterToQuery={searchText => ({ name: `%${searchText}%` })}
            optionText="name"
            noOptionsText="No items found"
        />
    ),
    ...rest
}) => (
    <ReferenceInput
        source={source}
        reference="items"
        filter={{ ...filter, fieldSelectOnFilterLine }}
        enableGetChoices={({ name }) => 'undefined' !== typeof name && 2 < name.trim().length}
        sort={sort}
    >
        {React.cloneElement(inputComponent, { ...rest })}
    </ReferenceInput>
);

const ItemLocationInput: React.FC<CommonFormInputProps> = ({
    label = 'Item Location',
    source = 'item_location_id',
    defaultCreateValues = {},
    enableGetChoices = () => true,
    filter = {},
    fieldSelectOnFilterLine = ['id', 'name'],
    sort = { field: 'name', order: 'ASC' },
    inputComponent = (
        <SelectInput
            create={<CreateSuggestionFormWrapper defaultCreateValues={defaultCreateValues} />}
            label={label}
            optionText="name"
            createLabel="Create Item Location"
            emptyText="Clear Selection"
        />
    ),
    ...rest
}) => (
    <ReferenceInput
        source={source}
        reference="item-locations"
        enableGetChoices={enableGetChoices}
        filter={{ ...filter, fieldSelectOnFilterLine }}
        sort={sort}
    >
        {React.cloneElement(inputComponent, { ...rest })}
    </ReferenceInput>
);

const ItemTypeInput: React.FC<CommonFormInputProps> = ({
    label = 'Item Type',
    source = 'item_type_id',
    filter = {},
    fieldSelectOnFilterLine = ['id', 'name'],
    sort = { field: 'name', order: 'ASC' },
    inputComponent = <SelectInput label={label} optionText="name" emptyText="Clear Selection" />,
    ...rest
}) => (
    <ReferenceInput source={source} reference="item-types" filter={{ ...filter, fieldSelectOnFilterLine }} sort={sort}>
        {React.cloneElement(inputComponent, { ...rest })}
    </ReferenceInput>
);

const ItemSubtypeInput: React.FC<CommonFormInputProps> = ({
    label = 'Item Subtype',
    source = 'item_subtype_id',
    defaultCreateValues = {},
    enableGetChoices = () => true,
    filter = {},
    fieldSelectOnFilterLine = ['id', 'name', 'parent_id'],
    sort = { field: 'name', order: 'ASC' },
    inputComponent = (
        <SelectInput
            create={<CreateSuggestionFormWrapper defaultCreateValues={defaultCreateValues} />}
            label={label}
            optionText="name"
            emptyText="Clear Selection"
        />
    ),
    ...rest
}) => (
    <ReferenceInput
        source={source}
        reference="item-subtypes"
        enableGetChoices={enableGetChoices}
        filter={{ ...filter, fieldSelectOnFilterLine }}
        sort={sort}
    >
        {React.cloneElement(inputComponent, { ...rest })}
    </ReferenceInput>
);

const MedicationInput: React.FC<CommonFormInputProps> = ({
    label = 'Medication',
    source = 'medication_id',
    defaultCreateValues = {},
    filter = {},
    fieldSelectOnFilterLine = ['id', 'name'],
    inputComponent = (
        <AutocompleteInput
            label={label}
            create={<CreateSuggestionFormWrapper defaultCreateValues={defaultCreateValues} />}
            shouldRenderSuggestions={val => 'undefined' !== typeof val && 2 < val.trim().length}
            filterToQuery={searchText => ({ name: `%${searchText}%` })}
            optionText="name"
            noOptionsText="No medications found"
        />
    ),
    perPage = 100,
    ...rest
}) => (
    <ReferenceInput
        source={source}
        reference="medications"
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ ...filter, fieldSelectOnFilterLine }}
        enableGetChoices={({ name }) => 'undefined' !== typeof name && 2 < name.trim().length}
        perPage={perPage}
    >
        {React.cloneElement(inputComponent, { ...rest })}
    </ReferenceInput>
);

const MedicationRouteInput: React.FC<CommonFormInputProps> = ({
    label = 'Medication Route',
    source = 'medication_route_id',
    defaultCreateValues = {},
    enableGetChoices = () => true,
    filter = {},
    fieldSelectOnFilterLine = ['id', 'name'],
    sort = { field: 'name', order: 'ASC' },
    inputComponent = <SelectInput label={label} optionText="name" emptyText="Clear Selection" />,
    ...rest
}) => (
    <ReferenceInput
        source={source}
        reference="medication-routes"
        filter={{ ...filter, fieldSelectOnFilterLine }}
        enableGetChoices={enableGetChoices}
        sort={sort}
    >
        {React.cloneElement(inputComponent, { ...rest })}
    </ReferenceInput>
);

const OrganizationInput: React.FC<CommonFormInputProps> = ({
    label = 'Organization',
    source = 'organization_id',
    filter = {},
    fieldSelectOnFilterLine = ['id', 'name'],
    inputComponent = (
        <AutocompleteInput
            label={label}
            shouldRenderSuggestions={val => 'undefined' !== typeof val && 2 < val.trim().length}
            filterToQuery={searchText => ({ name: `%${searchText}%` })}
            optionText="name"
            noOptionsText="No organizations found"
        />
    ),
    perPage = 100,
    ...rest
}) => (
    <ReferenceInput
        source={source}
        reference="organizations"
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ ...filter, fieldSelectOnFilterLine }}
        enableGetChoices={({ name }) => 'undefined' !== typeof name && 2 < name.trim().length}
        perPage={perPage}
    >
        {React.cloneElement(inputComponent, { ...rest })}
    </ReferenceInput>
);

const PaymentTermInput: React.FC<CommonFormInputProps> = ({
    label = 'Payment Term',
    source = 'payment_term_id',
    filter = {},
    fieldSelectOnFilterLine = ['id', 'name'],
    inputComponent = <SelectInput label={label} optionText="name" emptyText="Clear Selection" />,
    perPage = 100,
    ...rest
}) => (
    <ReferenceInput
        source={source}
        reference="payment-terms"
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ ...filter, fieldSelectOnFilterLine }}
        perPage={perPage}
    >
        {React.cloneElement(inputComponent, { ...rest })}
    </ReferenceInput>
);

const PenInput: React.FC<CommonFormInputProps> = ({
    label = 'Pen',
    source = 'pen_id',
    defaultCreateValues = {},
    filter = {},
    fieldSelectOnFilterLine = ['id', 'name'],
    inputComponent = (
        <AutocompleteInput
            label={label}
            create={<CreateSuggestionFormWrapper defaultCreateValues={defaultCreateValues} />}
            shouldRenderSuggestions={val => 'undefined' !== typeof val && 2 < val.trim().length}
            filterToQuery={searchText => ({ name: `%${searchText}%` })}
            optionText="name"
            noOptionsText="No pens found"
        />
    ),
    perPage = 100,
    ...rest
}) => (
    <ReferenceInput
        source={source}
        reference="pens"
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ ...filter, fieldSelectOnFilterLine }}
        enableGetChoices={({ name }) => 'undefined' !== typeof name && 2 < name.trim().length}
        perPage={perPage}
    >
        {React.cloneElement(inputComponent, { ...rest })}
    </ReferenceInput>
);

const PhoneInput = ({ label = 'Phone Number', source = 'phone', ...rest }) => (
    <TextInput
        source={source}
        label={label}
        format={phoneNumberFormat}
        parse={phoneText => {
            if (!phoneText) {
                return null;
            }
            const modifiedSearchText = phoneText.replace(/-|_|\(|\)|\s/g, '');
            return `${modifiedSearchText.trim()}`;
        }}
        {...rest}
    />
);

const SpecimenInput: React.FC<CommonFormInputProps> = ({
    label = 'Specimen',
    source = 'specimen_id',
    defaultCreateValues = {},
    filter = {},
    fieldSelectOnFilterLine = ['id', 'name'],
    inputComponent = (
        <AutocompleteInput
            label={label}
            create={<CreateSuggestionFormWrapper defaultCreateValues={defaultCreateValues} />}
            shouldRenderSuggestions={val => 'undefined' !== typeof val && 2 < val.trim().length}
            filterToQuery={searchText => ({ name: `%${searchText}%` })}
            optionText="name"
            noOptionsText="No specimens found"
        />
    ),
    perPage = 100,
    ...rest
}) => (
    <ReferenceInput
        source={source}
        reference="specimens"
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ ...filter, fieldSelectOnFilterLine }}
        enableGetChoices={({ name }) => 'undefined' !== typeof name && 2 < name.trim().length}
        perPage={perPage}
    >
        {React.cloneElement(inputComponent, { ...rest })}
    </ReferenceInput>
);

const UnitsOfMeasureInput: React.FC<CommonFormInputProps> = ({
    label = 'Units of Measure',
    source = 'unit_of_measure_id',
    defaultCreateValues = {},
    filter = {},
    fieldSelectOnFilterLine = ['id', 'name'],
    enableGetChoices = () => true,
    inputComponent = (
        <SelectInput
            label={label}
            create={<CreateSuggestionFormWrapper label="Unit of Measure" defaultCreateValues={defaultCreateValues} />}
            optionText="name"
            createLabel="Create Unit of Measure"
        />
    ),
    perPage = 100,
    ...rest
}) => (
    <ReferenceInput
        source={source}
        reference="units-of-measure"
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ ...filter, fieldSelectOnFilterLine }}
        enableGetChoices={enableGetChoices}
        perPage={perPage}
    >
        {React.cloneElement(inputComponent, { ...rest })}
    </ReferenceInput>
);

const UserInput: React.FC<CommonFormInputProps> = ({
    label = 'User',
    source = 'user_id',
    filter = {},
    fieldSelectOnFilterLine = ['id', 'name'],
    sort = { field: 'name', order: 'ASC' },
    inputComponent = (
        <AutocompleteInput
            label={label}
            shouldRenderSuggestions={val => 'undefined' !== typeof val && 2 < val.trim().length}
            filterToQuery={searchText => ({ name: `%${searchText}%` })}
            optionText="name"
            noOptionsText="No users found"
        />
    ),
    ...rest
}) => (
    <ReferenceInput
        source={source}
        reference="users"
        sort={sort}
        filter={{ ...filter, fieldSelectOnFilterLine }}
        enableGetChoices={({ name }) => 'undefined' !== typeof name && 2 < name.trim().length}
    >
        {React.cloneElement(inputComponent, { ...rest })}
    </ReferenceInput>
);

const VendorInput: React.FC<CommonFormInputProps> = ({
    label = 'Vendor',
    source = 'vendor_id',
    defaultCreateValues = {},
    filter = {},
    fieldSelectOnFilterLine = ['id', 'name'],
    inputComponent = (
        <AutocompleteInput
            label={label}
            create={<CreateSuggestionFormWrapper defaultCreateValues={{ ...defaultCreateValues, type: 'Vendor' }} />}
            shouldRenderSuggestions={val => 'undefined' !== typeof val && 2 < val.trim().length}
            filterToQuery={searchText => ({ name: `%${searchText}%` })}
            optionText="name"
            emptyText="Clear Selection"
            noOptionsText="No vendors found"
        />
    ),
    perPage = 100,
    ...rest
}) => (
    <ReferenceInput
        source={source}
        reference="vendors"
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ ...filter, fieldSelectOnFilterLine }}
        enableGetChoices={({ name }) => 'undefined' !== typeof name && 2 < name.trim().length}
        perPage={perPage}
    >
        {React.cloneElement(inputComponent, { ...rest })}
    </ReferenceInput>
);

export {
    AccountInput,
    BreedingRecordInput,
    CodonInput,
    ContactInput,
    CustomerInput,
    FacilityInput,
    HourInput,
    ItemsInput,
    ItemLocationInput,
    ItemTypeInput,
    ItemSubtypeInput,
    PaymentTermInput,
    PhoneInput,
    MedicationInput,
    MedicationRouteInput,
    OrganizationInput,
    PenInput,
    SpecimenInput,
    UnitsOfMeasureInput,
    UserInput,
    VendorInput,
};
