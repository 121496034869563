import React from 'react';
import { BooleanInput, TextInput, NumberInput, SelectInput, useNotify, required } from 'react-admin';
import { DateTimeInput } from '../../../lib/customComponents/formComponents';
import { ColumnShowView, SingleColumn, FullScreen } from '../../../lib/styles/StyledComponents';
import { ContactInput, SpecimenInput } from '../../../lib/customComponents/formComponents/CommonFormInputs';
import { requestGetList } from '../../../../dataProvider/RestClient';

const breedingMethodChoices = [
    { value: 'AI', name: 'AI' },
    { value: 'Cover', name: 'Cover' },
    { value: 'Live', name: 'Live' },
];

const OverviewInputs = ({ edit = false, ...props }) => {
    const [contactRecord, setContactRecord] = React.useState({ id: null });

    const notify = useNotify();

    const today = React.useMemo(() => new Date(), []);

    const user = React.useMemo(
        () => localStorage.getItem('user') && JSON.parse(localStorage.getItem('user') || '{}'),
        []
    );

    const getDefaultContact = React.useCallback(
        async userID => {
            if (!userID) {
                return;
            }
            await requestGetList('contacts', { user_id: userID }, 'id', 'ASC', 2, ['id', 'name', 'user_id'])
                .then(res => {
                    if (res.data[0]) {
                        setContactRecord(res.data[0]);
                    }
                })
                .catch(() => notify('Problem getting contact record, defaults could not load', { type: 'warning' }));
        },
        [notify]
    );

    React.useEffect(() => {
        if (user && user.id) {
            getDefaultContact(user.id);
        }
    }, [user, getDefaultContact]);

    return (
        <>
            <ColumnShowView>
                <ContactInput
                    source="administered_by_id"
                    label="Administered By"
                    defaultValue={contactRecord.id}
                    validate={required()}
                />
            </ColumnShowView>
            <ColumnShowView>
                <SpecimenInput
                    source="male_specimen_id"
                    label="Male Specimen"
                    filter={{ gender: 'Male' }}
                    validate={required()}
                />
            </ColumnShowView>
            <ColumnShowView>
                <SelectInput
                    source="type"
                    label="Type"
                    choices={breedingMethodChoices}
                    optionValue="value"
                    validate={required()}
                    emptyText="Clear Selection"
                />
            </ColumnShowView>
            <ColumnShowView>
                <DateTimeInput source="bred_at" label="Bred At" validate={required()} defaultValue={today} />
            </ColumnShowView>
            <ColumnShowView>
                <NumberInput source="male_specimen_weight_pounds" label="Male Specimen Weight (lbs)" />
            </ColumnShowView>
            <ColumnShowView>
                <NumberInput source="male_specimen_score" label="Male Specimen Score" />
            </ColumnShowView>
            <FullScreen>
                <TextInput multiline source="notes" />
            </FullScreen>
            <SingleColumn>
                <BooleanInput source="is_active" label="Active" defaultValue />
            </SingleColumn>
        </>
    );
};

export default OverviewInputs;
