import React, { useState } from 'react';
import { ArrayInput, required, NumberInput, TextInput, Labeled } from 'react-admin';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { SpecimenInput } from '../../../lib/customComponents/formComponents/CommonFormInputs';
import { SimpleFormIterator } from '../../../lib/MarmeCustomComponents';
import FemaleSpecimenFilters from './FemaleSpecimenFilters';

const mustBeCompleteLineItem = (value, allValues) => {
    const { breeding_record_lines: breedingRecordLines } = allValues;

    if (!breedingRecordLines) {
        return 'Breeding record line items are required!';
    }
    if (breedingRecordLines.some(line => !line.specimen_id)) {
        return 'Breeding record lines must contain a female specimen';
    }

    return undefined;
};

const FemaleFiltersChipArray = ({ penName, facilityName, femaleSpecimenFilters }) => {
    const getChipValue = (key, val) => {
        if ('transferred_to_id' === key) {
            if (penName) {
                return penName;
            }
            if (facilityName) {
                return facilityName;
            }
        }
        return val;
    };

    return (
        <Stack direction="row" spacing={1}>
            {Object.entries(femaleSpecimenFilters).map(([key, value]: any) =>
                value ? (
                    <Chip
                        key={key}
                        label={`${key
                            .replace('Modal', '')
                            .replace('_id', ' Name')
                            .split('_')
                            .map(word => word[0].toUpperCase() + word.substr(1))
                            .join(' ')}: ${getChipValue(key, value)}`}
                    />
                ) : null
            )}
        </Stack>
    );
};

const BreedingRecordsLinesForm = ({ edit = false, ...props }) => {
    const [femaleSpecimenFilters, setFemaleSpecimenFilters] = useState({ gender: 'Female' });
    const [penName, setPenName] = useState(null);
    const [facilityName, setFacilityName] = useState(null);

    return (
        <>
            <Labeled label="Female Specimen Filters">
                <FemaleSpecimenFilters
                    setPenName={setPenName}
                    setFacilityName={setFacilityName}
                    femaleSpecimenFilters={femaleSpecimenFilters}
                    setFemaleFilters={setFemaleSpecimenFilters}
                />
            </Labeled>
            <FemaleFiltersChipArray
                penName={penName}
                facilityName={facilityName}
                femaleSpecimenFilters={femaleSpecimenFilters}
            />
            <ArrayInput
                source="breeding_record_lines"
                label="Line Items"
                validate={[required(), mustBeCompleteLineItem]}
            >
                <SimpleFormIterator disableReordering>
                    <SpecimenInput source="specimen_id" filter={femaleSpecimenFilters} validate={required()} />
                    <NumberInput source="specimen_weight_pounds" label="Specimen Weight (lbs)" />
                    <NumberInput source="specimen_score" />
                    <TextInput multiline source="notes" />
                </SimpleFormIterator>
            </ArrayInput>
        </>
    );
};

export default BreedingRecordsLinesForm;
