import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    useRecordContext,
    Toolbar,
    useDataProvider,
    useNotify,
    useRedirect,
    SaveButton,
    useResourceContext,
    useCreateSuggestionContext,
} from 'react-admin';
import { useFormContext, useFormState } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import 'react-sweet-progress/lib/style.css';
import SaveProgress from './SaveProgress';
import useAttachments from './useAttachments';
import useEntityProcessing from './useEntityProcessing';
import AlertWindow from './AlertWindow';
import ToolbarErrorWindow from './ToolbarErrorWindow';
import { LoadingWindow } from '../../index';
import CreateSuggestionCancelButton from './CreateSuggestionCancelButton';

type TCustomToolbar = {
    [x: string]: any;
    subentity?: { [x: string]: string[] };
    closeDialog?: () => void;
    resourceOverride?: string;
    customSaveButton?: JSX.Element;
    redirectPathOverride?: string;
    transform: (rec: any) => any;
    onSuccess?: (data) => void;
    onError?: (data) => void;
    dontSendRecordID?: boolean;
    subentityNotRequired?: boolean;
    awaitNotification?: boolean;
    enableNotification?: boolean;
    noInitialRequirement?: boolean;
    sx?: any;
};

const useRedirectFromQuery = initialValue => {
    const [redirect, setRedirect] = useState(initialValue);
    const { search } = useLocation();

    useEffect(() => {
        const parsedQuery = queryString.parse(search);
        if (parsedQuery && parsedQuery.redirect) {
            setRedirect(parsedQuery.redirect);
        }
    }, [search]);

    return redirect;
};

const CustomToolbar: React.FC<TCustomToolbar> = ({
    subentity,
    closeDialog,
    resourceOverride,
    customSaveButton,
    transform,
    onSuccess,
    onError,
    forceCreate = false,
    redirectPathOverride = 'list',
    dontSendRecordID = false,
    subentityNotRequired = false,
    awaitNotification = false,
    enableNotification = true,
    noInitialRequirement = false,
    redirectEnabled = true,
    sx,
    ...props
}) => {
    const [alertWindow, setAlertWindow] = useState(false);
    const [errorWindow, setErrorWindow] = useState(false);
    const [attachmentsWindow, setAttachmentsWindow] = useState(false);
    const [recordSent, setRecordSent] = useState(false);
    const resource = useResourceContext();

    const toolbarResource = resourceOverride || resource;
    const record = useRecordContext(props);
    const createSuggestionContext = useCreateSuggestionContext();

    const formContext = useFormContext();
    const { errors, isDirty } = useFormState();
    const shouldDisable = !isDirty && !noInitialRequirement && errors && 0 < Object.entries(errors).length;

    const isMountedRef = useRef(false);

    const { updateFiles } = useAttachments(toolbarResource);

    const notify = useNotify();
    const redirectPath = useRedirectFromQuery(redirectPathOverride);
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const handleError = useCallback(
        (error, attachmentsErr = false) => {
            if ('function' === typeof onError) {
                onError(error);
            }
            setAlertWindow(false);

            if (attachmentsErr) {
                setAttachmentsWindow(false);
                return notify('Error attempting to submit attachments!', { type: 'warning' });
            }

            notify('Error attempting to submit form data!', { type: 'warning' });
        },
        [notify, onError]
    );

    const sendFiles = useCallback(
        response => {
            setAttachmentsWindow(true);
            return updateFiles(response).catch(err => handleError(err, true));
        },
        [handleError, updateFiles]
    );

    useEntityProcessing({ resource: toolbarResource, sendFiles, redirectPath, recordSent, onSuccess, onError });

    const createOrUpdate = useCallback(
        async vals => {
            if ('bypass-create' === toolbarResource) {
                return onSuccess(vals);
            }
            let result;
            const payload = transform ? transform(vals) : vals;

            if (dontSendRecordID) {
                result = await dataProvider
                    // @ts-ignore
                    .update(toolbarResource, { data: payload })
                    .then(({ data }) => data)
                    .catch(handleError);
            } else if (record && record.id && !forceCreate) {
                result = await dataProvider
                    // @ts-ignore
                    .update(toolbarResource, { id: record.id, data: payload })
                    .then(({ data }) => data)
                    .catch(handleError);
            } else {
                result = await dataProvider
                    .create(toolbarResource, { data: payload })
                    .then(({ data }) => data)
                    .catch(handleError);
            }

            if (awaitNotification && isMountedRef.current) {
                setRecordSent(true);
                return;
            }

            if (isMountedRef.current) {
                await sendFiles(payload);

                setAlertWindow(false);
                setAttachmentsWindow(false);

                if ('function' === typeof onSuccess) {
                    onSuccess(result);
                }
                if (redirectEnabled && !createSuggestionContext) {
                    redirect(redirectPath, resource);
                }
                if (result && enableNotification) {
                    const notifString = record && record.id ? 'updated' : 'created';
                    notify(
                        result.id
                            ? `${toolbarResource} #${result.id} ${notifString}`
                            : `${toolbarResource} ${notifString}`,
                        { type: 'success' }
                    );
                }
            }
        },
        [
            transform,
            forceCreate,
            enableNotification,
            awaitNotification,
            dataProvider,
            dontSendRecordID,
            handleError,
            notify,
            onSuccess,
            record,
            resource,
            redirect,
            redirectPath,
            sendFiles,
            toolbarResource,
            redirectEnabled,
            createSuggestionContext,
        ]
    );

    const handleClick: (e: any, formValue?: { [x: string]: any }) => Promise<void> = useCallback(
        async (e, formValue) => {
            const ignoreSubentity = !subentity || true === subentityNotRequired;
            e.preventDefault();

            await formContext.trigger().then(formIsValid => {
                const values = formContext.getValues();

                const subentities = subentity && Object.keys(subentity);

                const hasLineErrors =
                    subentities &&
                    subentities.some(
                        sub =>
                            values &&
                            values[sub] &&
                            values[sub].some(line => subentity[sub].some(field => !line[field]))
                    );

                const hasSubentityValues =
                    subentities && subentities.some(val => values[val] && 0 < values[val].length);

                if (!formIsValid || (!ignoreSubentity && (hasLineErrors || !hasSubentityValues))) {
                    return setErrorWindow(true);
                }

                if (ignoreSubentity || hasSubentityValues || !subentities) {
                    setAlertWindow(true);

                    return createOrUpdate(
                        formValue && Object.values(formValue).length ? { ...values, ...formValue } : values
                    );
                }
            });
        },
        [createOrUpdate, subentity, subentityNotRequired, formContext]
    );

    const ToolbarSaveButton = useCallback(
        () =>
            customSaveButton && React.isValidElement(customSaveButton) ? (
                React.cloneElement(customSaveButton, {
                    // @ts-ignore
                    handleClick,
                    transform,
                    onSuccess,
                    onError,
                    disabled: shouldDisable,
                    ...props,
                })
            ) : (
                <SaveButton
                    label="save"
                    alwaysEnable={noInitialRequirement}
                    onMouseDown={handleClick}
                    disabled={shouldDisable}
                />
            ),
        [customSaveButton, handleClick, onError, onSuccess, props, transform, shouldDisable, noInitialRequirement]
    );

    React.useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    return (
        <Toolbar sx={sx}>
            {attachmentsWindow && (
                <LoadingWindow isLoading={attachmentsWindow} linear simpleMessage content="Processing attachments..." />
            )}
            <AlertWindow
                isOpen={alertWindow}
                errors={errors}
                onClose={() => setAlertWindow(false)}
                subentity={subentity}
            />
            <ToolbarErrorWindow isOpen={errorWindow} onClose={() => setErrorWindow(false)} errors={errors} />
            {/* <SaveProgress subentities={subentity} /> */}
            <CreateSuggestionCancelButton />
            <ToolbarSaveButton />
        </Toolbar>
    );
};

export default CustomToolbar;
