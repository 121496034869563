import React, { Children, isValidElement, cloneElement } from 'react';
import type { DetailedReactHTMLElement, HTMLAttributes } from 'react';

const setToolbarWithProps: (
    toolbar: any,
    resource: string,
    closeDialog: () => void
) => DetailedReactHTMLElement<HTMLAttributes<HTMLElement>, HTMLElement> | null = (toolbar, resource, closeDialog) => {
    const hasToolbar = isValidElement(toolbar);

    return hasToolbar
        ? cloneElement(toolbar, {
              // @ts-ignore
              basePath: `/${resource}`,
              resource,
              // @ts-ignore
              resourceOverride: toolbar.props.resourceOverride || resource,
              onSuccess: data => {
                  // @ts-ignore
                  if (toolbar.props && 'function' === typeof toolbar.props.onSuccess) {
                      // @ts-ignore
                      return toolbar.props.onSuccess({ data, closeDialog });
                  }
                  closeDialog();
              },
              onError: data => {
                  // @ts-ignore
                  if (toolbar.props && 'function' === typeof toolbar.props.onError) {
                      // @ts-ignore
                      return toolbar.props.onError({ data, closeDialog });
                  }
                  closeDialog();
              },
          })
        : null;
};

const FormWithProps = ({ form, closeDialog, passCloseDialogToForm }) => {
    const { children: innerChild, resource, ...rest } = form.props;

    if (!isValidElement(form)) {
        throw new Error('Form must be valid React element!');
    }

    let formElementProps = {
        title: ' ',
        children:
            isValidElement(innerChild) && Children.only(innerChild)
                ? cloneElement(innerChild, {
                      // @ts-ignore
                      toolbar: setToolbarWithProps(innerChild.props.toolbar, resource, closeDialog),
                  })
                : undefined,
        ...rest,
    };

    if (passCloseDialogToForm) {
        formElementProps = { ...formElementProps, closeDialog };
    }

    const FormElement = cloneElement(form, { ...formElementProps });

    return FormElement;
};

export default FormWithProps;
