import React from 'react';
import { ListProps, SimpleShowLayout, useRecordContext, usePermissions } from 'react-admin';
import Chip from '@mui/material/Chip';
import { blue, pink } from '@mui/material/colors';
import { formatDate, shouldRender } from '../../lib/helpers';
import {
    BreedButton,
    ListWithViewContext,
    MedicateButton,
    ReferenceDrawerField,
    RelationTable,
    TransferButton,
} from '../../lib/customComponents/showComponents';
import SpecimenFilters from './SpecimenFilters';
import BulkTransferButton from './BulkTransferButton';
import SpecimensActionsMenu from './SpecimensActionsMenu';

export const SpecimenMedicationRecords = React.memo(props => (
    <RelationTable
        columns={{
            id: 'ID',
            administered_by_name: 'Administered By',
            medication_name: 'Medication',
            amount: 'Amount',
            unit_of_measure_name: 'Unit of Measure',
            medication_route_name: 'Medication Route',
            administered_at: 'Administered At',
            notes: 'Notes',
        }}
        title="Medication Records"
        relation="medication-records"
        additionalFields={['administered_by_id', 'medication_id', 'unit_of_measure_id', 'medication_route_id']}
        formatToDrawerLink={[
            {
                name: 'id',
                resource: 'medication-records',
            },
            {
                name: 'administered_by_name',
                resource: 'contacts',
                idField: 'administered_by_id',
            },
            {
                name: 'medication_name',
                resource: 'medications',
                idField: 'medication_id',
            },
            {
                name: 'unit_of_measure_name',
                resource: 'units-of-measure',
                idField: 'unit_of_measure_id',
            },
            {
                name: 'medication_route_name',
                resource: 'medication-routes',
                idField: 'medication_route_id',
            },
        ]}
        formatToDate={[{ name: 'administered_at', format: 'date-time' }]}
        {...props}
    />
));

export const SpecimenBreedingRecordLines = React.memo(props => {
    const record = useRecordContext(props);
    const isMale = record?.gender && 'Male' === record.gender;
    return (
        <RelationTable
            columns={{
                breeding_record_id: 'Breeding Record ID',
                specimen_name: 'Specimen',
                male_specimen_name: 'Male Specimen',
                bred_at: 'Bred At',
                notes: 'Notes',
            }}
            title="Breeding Records"
            relation="breeding-record-lines"
            idField={isMale ? 'male_specimen_id' : null}
            additionalFields={['specimen_id', 'male_specimen_id']}
            formatToDrawerLink={[
                {
                    name: 'breeding_record_id',
                    resource: 'breeding-records',
                },
                {
                    name: 'specimen_name',
                    resource: 'specimens',
                    idField: 'specimen_id',
                },
                {
                    name: 'male_specimen_name',
                    resource: 'specimens',
                    idField: 'male_specimen_id',
                },
            ]}
            formatToDate={[{ name: 'bred_at', format: 'date-time' }]}
            {...props}
        />
    );
});

const SpecimensBulkActionButtons = props => {
    const { permissions } = usePermissions();

    const canCreateTransfers = React.useMemo(
        () => shouldRender(permissions, 'specimen-transfers', 'can_create'),
        [permissions]
    );

    if (!canCreateTransfers) {
        return null;
    }

    return <BulkTransferButton />;
};

const SpecimenExpand = props => (
    <SimpleShowLayout {...props}>
        <SpecimenMedicationRecords {...props} />
        <SpecimenBreedingRecordLines {...props} />
    </SimpleShowLayout>
);

export const getGenderIcon = gender => {
    switch (gender) {
        case 'Female':
            return <Chip label="F" sx={{ backgroundColor: pink[300] }} />;
        case 'Male':
        default:
            return <Chip label="M" sx={{ backgroundColor: blue[300] }} />;
    }
};

const specimensDefaultColumns = [
    'id',
    'name',
    'gender',
    'father_name',
    'mother_name',
    'bred_at',
    'born_at',
    'died_at',
    'created_at',
    'updated_at',
];

const specimensSpecialColumns = () => [
    <ReferenceDrawerField source="father_name" label="Father" reference="specimens" />,
    <ReferenceDrawerField source="mother_name" label="Mother" reference="specimens" />,
    <ReferenceDrawerField source="breeding_record_id" descSource="breeding_record_id" label="Breeding Record ID" />,
    <ReferenceDrawerField
        source="bred_at"
        reference="breeding-records"
        idSource="breeding_record_id"
        label="Bred At"
        isDateField
    />,
];

const SpecimenActions = () => <SpecimensActionsMenu />;

export const SpecimensList: React.FC<ListProps> = React.memo(props => (
    <ListWithViewContext
        title="Specimens"
        sort={{ field: 'name', order: 'ASC' }}
        alwaysOnFilters={['name']}
        filters={<SpecimenFilters />}
        bulkActionButtons={<SpecimensBulkActionButtons />}
        rowClick="toggleSelection"
        expand={<SpecimenExpand />}
        defaultColumns={specimensDefaultColumns}
        specialCols={specimensSpecialColumns}
        additionalButtons={[<MedicateButton />, <BreedButton />, <TransferButton />]}
        leftIcon={record => record?.gender && getGenderIcon(record.gender)}
        rightIcon={SpecimenActions}
        primaryText={record => record?.name}
        secondaryText={record => record?.electronic_number}
        tertiaryText={record => record?.updated_at && `Updated: ${formatDate(record.updated_at, 'short-date-time')}`}
        {...props}
    />
));

export default SpecimensList;
