import React, { useCallback, useMemo, useState } from 'react';
import { Create, SimpleForm, useNotify } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { requestGetByID, requestGetList } from '../../../../../dataProvider/RestClient';
import { DialogForm } from '../../../../lib/customComponents/formComponents';
import FemaleFilterToolbar from './FemaleFilterToolbar';
import FemaleFilterInputs from './FemaleFilterInputs';

const sanitizedData = ({ modal_gender: modalGender, ...rest }) => {
    const newFilters: any = {};

    Object.entries(rest).forEach(([key, value]) => {
        if (key.includes('modal_')) {
            return (newFilters.transferred_to_id = value);
        }
        if ('location_type' === key) {
            return (newFilters.transferred_to_entity = `App\\Entities\\${value}`);
        }
        if ('isSetAll' === key) return;
        newFilters[key] = value;
    });

    return newFilters;
};

const FemaleSpecimenFilters = ({ setPenName, setFacilityName, femaleSpecimenFilters, setFemaleFilters }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const notify = useNotify();

    const { setValue } = useFormContext();

    const getLocation = useCallback(async (id, location = 'pens') => {
        const loc = await requestGetByID(location, id).then(({ data }) => data.name);
        return loc;
    }, []);

    const onSuccess = useCallback(
        async ({ data, closeDialog }) => {
            setIsLoading(true);
            if (data.modal_pen_id) {
                const penName = await getLocation(data.modal_pen_id);
                if (penName && setPenName && 'function' === typeof setPenName) {
                    setPenName(penName);
                    setFacilityName(null);
                }
            }

            if (data.modal_facility_id) {
                const facilityName = await getLocation(data.modal_facility_id, 'facilities');
                if (facilityName && setFacilityName && 'function' === typeof setFacilityName) {
                    setPenName(null);
                    setFacilityName(facilityName);
                }
            }

            if (setFemaleFilters && 'function' === typeof setFemaleFilters) {
                setFemaleFilters(prev => ({ ...prev, ...sanitizedData(data) }));
            }

            if (data.isSetAll) {
                const newSpecimens = await requestGetList('specimens', {
                    gender: 'female',
                    ...sanitizedData(data),
                }).then(({ data: specimensData }) => specimensData);
                if (newSpecimens?.length) {
                    const specimenIdFormVals = newSpecimens.map(s => ({ specimen_id: s.id }));

                    setValue('breeding_record_lines', specimenIdFormVals);
                    notify('Matching specimen loaded into form', {
                        type: 'success',
                    });
                }
            } else {
                notify('New filters set for female specimens', {
                    type: 'success',
                });
            }

            setIsLoading(false);

            closeDialog();
        },
        [getLocation, setPenName, setFacilityName, setFemaleFilters, notify, setValue]
    );

    const newDefaultValues = useMemo(() => {
        const isPenForm = 'App\\Entities\\Pen' === femaleSpecimenFilters?.transferred_to_entity;

        const defaultVals = {
            // eslint-disable-next-line no-nested-ternary
            location_type: femaleSpecimenFilters.transferred_to_entity ? (isPenForm ? 'Pen' : 'Facility') : '',
        };
        if (femaleSpecimenFilters.transferred_to_id) {
            if (isPenForm) {
                return { ...defaultVals, modal_pen_id: femaleSpecimenFilters.transferred_to_id };
            }
            return { ...defaultVals, modal_facility_id: femaleSpecimenFilters.transferred_to_id };
        }
        return defaultVals;
    }, [femaleSpecimenFilters]);

    return (
        <DialogForm
            title="Select Female Specimen Filters"
            label="Select Female Filters"
            maxWidth="sm"
            buttonMarginLeft="0em"
            passCloseDialogToForm={false}
        >
            {isLoading ? (
                <Stack sx={{ minHeight: '12em' }} alignItems="center">
                    <CircularProgress sx={{ marginTop: '1em' }} size="8em" />
                </Stack>
            ) : (
                <Create sx={{ width: '100%' }}>
                    <SimpleForm
                        defaultValues={newDefaultValues}
                        mode="all"
                        toolbar={
                            <FemaleFilterToolbar
                                femaleSpecimenFilters={femaleSpecimenFilters}
                                setFemaleFilters={setFemaleFilters}
                                onSuccess={onSuccess}
                            />
                        }
                    >
                        <FemaleFilterInputs femaleSpecimenFilters={femaleSpecimenFilters} />
                    </SimpleForm>
                </Create>
            )}
        </DialogForm>
    );
};

export default FemaleSpecimenFilters;
